<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    transition="dialog-transition"
    :persistent="loading"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        color="color2Text"
        icon
        v-on="on"
      >
        <v-icon small>fas fa-pencil</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        Update Court Name
      </v-card-title>
      <v-card-text>
        <v-text-field
          label="Court Name"
          v-model="name"
          color="color3"
          :disabled="loading"
        ></v-text-field>
        <v-expand-transition>
          <div v-if="loading" class="text-center">Please, hold on while we find and update the matches for this court...</div>
        </v-expand-transition>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="success"
          small fab
          @click.stop="save"
          :loading="loading"
        >
          <v-icon>fas fa-save</v-icon>
        </v-btn>
        <v-btn
          color="error"
          small fab
          @click.stop="dialog = false"
          :disabled="loading"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['court', 'courtI'],
  data () {
    return {
      dialog: false,
      name: null,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['tournament']),
    leagueSettings () {
      return this.tournament.leagueSettings
    }
  },
  methods: {
    save () {
      this.loading = true
      var names = [...this.leagueSettings.courtNames]
      names[this.courtI] = this.name
      this.tournament.jProps.leagueSettings._courtNames = names
      this.tournament.saveJProps()
        .then(r => {
          this.dialog = false
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
        })
    }
  },
  watch: {
    dialog: function (v) {
      if (v) {
        this.name = this.court
      }
    }
  }
}
</script>
